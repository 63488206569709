import * as React from "react"
import { Layout } from "../../components/layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const DogsPage = (props) => {
  const data = props.data.allHundeYaml.nodes

  const youngster = data.filter((dog) => dog.type === 0)
  const zucht = data.filter((dog) => dog.type === 1)
  const ruhestand = data.filter((dog) => dog.type === 2)
  const unvergessen = data.filter((dog) => dog.type === 3)
  const rueden = data.filter((dog) => dog.type === 4)

  return (
    <>
      <Layout
        title='Hunde'
        image={props.data.file.childImageSharp.gatsbyImageData}
      >
        <main className='container-xl'>
          <h1>Hunde</h1>
          {youngster.lenght > 0 ? (
            <>
              <h2 id='#youngster' className='text-center'>
                Youngster
              </h2>
              <section className='row justify-content-evenly'>
                {youngster.map(({ name, fullName, teaser }) => (
                  <Link
                    className='py-5 col-12 col-sm-6 col-lg-4'
                    style={{ textDecoration: "none" }}
                    to={`/hunde/${name}/`}
                  >
                    <GatsbyImage
                      image={teaser.childImageSharp.gatsbyImageData}
                      alt={name}
                      className='shadow image-link'
                      loading='lazy'
                    />
                    <h2 className='text-center pt-3 text-capitalize'>{name}</h2>
                    <p className='text-center text-black'>{fullName}</p>
                  </Link>
                ))}
              </section>
            </>
          ) : null}
          <h2 id='#zuchthunde' className='text-center'>
            Zuchthündin
          </h2>
          <section className='row justify-content-evenly'>
            {zucht.map(({ name, fullName, teaser }) => (
              <Link
                className='py-5 col-12 col-sm-6 col-lg-4'
                style={{ textDecoration: "none" }}
                to={`/hunde/${name}/`}
              >
                <GatsbyImage
                  image={teaser.childImageSharp.gatsbyImageData}
                  alt={name}
                  className='shadow image-link'
                  loading='lazy'
                />
                <h2 className='text-center pt-3 text-capitalize'>{name}</h2>
                <p className='text-center text-black'>{fullName}</p>
              </Link>
            ))}
          </section>
          <h2 id='#ruede' className='text-center'>
            Deckrüde Oisin
          </h2>
          <section className='row justify-content-evenly'>
            {rueden.map(({ name, fullName, teaser }) => (
              <Link
                className='py-5 col-12 col-sm-6 col-lg-4'
                style={{ textDecoration: "none" }}
                to={`/hunde/${name}/`}
              >
                <GatsbyImage
                  image={teaser.childImageSharp.gatsbyImageData}
                  alt={name}
                  className='shadow image-link'
                  loading='lazy'
                />
                <h2 className='text-center pt-3 text-capitalize'>{name}</h2>
                <p className='text-center text-black'>{fullName}</p>
              </Link>
            ))}
          </section>
          <h2 id='#ruhestand' className='text-center'>
            Im Ruhestand
          </h2>
          <section className='row justify-content-evenly'>
            {ruhestand.map((dog) => (
              <Link
                className='py-5 col-12 col-sm-6 col-lg-4'
                style={{ textDecoration: "none" }}
                to={`/hunde/${dog.name}`}
              >
                <GatsbyImage
                  image={dog.teaser.childImageSharp.gatsbyImageData}
                  className='shadow image-link'
                  alt={dog.name}
                  loading='lazy'
                />
                <h2 className='text-center pt-3 text-capitalize'>{dog.name}</h2>
                <p className='text-center text-black'>{dog.fullName}</p>
              </Link>
            ))}
          </section>
          <h2 id='#unvergessen' className='text-center'>
            für immer in meinem Herzen
          </h2>
          <section className='row justify-content-evenly'>
            {unvergessen.map((dog) => {
              if (dog.type === 5) {
                return (
                  <div className='py-5 col-12 col-sm-6 col-lg-4'>
                    <GatsbyImage
                      image={dog.teaser.childImageSharp.gatsbyImageData}
                      className='shadow image-link'
                      alt={dog.name}
                      loading='lazy'
                    />
                    <h2 className='text-center pt-3 text-capitalize'>
                      {dog.name}
                    </h2>
                    <p className='text-center text-black'>{dog.fullName}</p>
                    <p className='text-center text-black fst-italic'>
                      {dog.birthDate}
                    </p>
                  </div>
                )
              } else {
                return (
                  <Link
                    className='py-5 col-12 col-sm-6 col-lg-4'
                    style={{ textDecoration: "none" }}
                    to={`/hunde/${dog.name}`}
                  >
                    <GatsbyImage
                      image={dog.teaser.childImageSharp.gatsbyImageData}
                      className='shadow image-link'
                      alt={dog.name}
                      loading='lazy'
                    />
                    <h2 className='text-center pt-3 text-capitalize'>
                      {dog.name}
                    </h2>
                    <p className='text-center text-black'>{dog.fullName}</p>
                    <p className='text-center text-black fst-italic'>
                      {dog.birthDate}
                    </p>
                  </Link>
                )
              }
            })}
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query DogsPageQuery {
    allHundeYaml {
      nodes {
        name
        fullName
        type
        birthDate
        teaser {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    file(relativePath: { eq: "teaser/teaser_dogs.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default DogsPage
